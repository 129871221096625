import { t } from 'localization'
import { OfferModalDetails } from 'offers/types'
import { formatPrice } from 'shared/components/ProductGrid/__utils__/formatPrice'
import { formatMoneyObject } from 'shared/lib/formatMoneyObject'

// Note: These should be coming from the BE but still awaiting endpoint
const shortCutPercentages = [
  { display: '5% off', percentageValue: 0.05 },
  { display: '10% off', percentageValue: 0.1 },
  { display: '15% off', percentageValue: 0.15 },
]

// Note: These should be coming from the BE but still awaiting endpoint
export const expirationDays = [3, 7, 14, 30]

const TP = 'offers.components.OffersModalContents'

export const getShortcutValues = ({
  offerModalDetails,
}: {
  offerModalDetails: OfferModalDetails | null
}) =>
  shortCutPercentages.map((shortcut) => {
    const value = offerModalDetails?.lowestPriceCents?.value || 0
    const display = formatMoneyObject(
      {
        currency: 'USD',
        amount: value - value * shortcut.percentageValue,
        amountUsdCents: value - value * shortcut.percentageValue,
      },
      { showCents: false },
    )
    return {
      display,
      value: value - value * shortcut.percentageValue,
      percentageDisplay: shortcut.display,
    }
  })

export const getNumberValue = (string: string = '0') => {
  const strippedValue = string.replace(/[^0-9]/g, '')
  return parseInt(strippedValue)
}

export const getExpirationDate = (expirationValue: number) => {
  const date = new Date()
  date.setDate(date.getDate() + expirationValue)

  const formatData = (input: number) => {
    if (input > 9) {
      return input
    }
    return `0${input}`
  }

  const dd = formatData(date.getDate())
  const mm = formatData(date.getMonth() + 1)
  const yyyy = date.getFullYear()

  const time = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  return `Expires ${mm}/${dd}/${yyyy} - ${time}`
}

export const getRemainingOfferTime = (offer: { activatedAt: string; offerDuration: number }) => {
  const activatedAt = new Date(offer.activatedAt)
  const expirationDate = new Date(activatedAt.getTime() + offer.offerDuration * 60 * 60 * 1000)
  const currentDate = new Date()
  const remainingTime = expirationDate.getTime() - currentDate.getTime()

  const d = remainingTime / 1000 / 60 / 60 / 24
  const h = (remainingTime / 1000 / 60 / 60) % 24
  const m = (remainingTime / 1000 / 60) % 60

  const days = remainingTime < 0 ? Math.ceil(d) : Math.floor(d)
  const hours = remainingTime < 0 ? Math.ceil(h) : Math.floor(h)
  const minutes = remainingTime < 0 ? Math.ceil(m) : Math.floor(m)

  return {
    days,
    hours,
    minutes,
    display: `${days}D ${hours}H ${minutes}M`,
    isLessThanADay: days === 0 && hours >= 0 && minutes > 0,
    expired: remainingTime < 0,
  }
}

export const getSizingLabel = (offerModalDetails: OfferModalDetails | null) => {
  if (!offerModalDetails) return ''

  const sizeCategory = offerModalDetails?.sizeCategory.charAt(0).toUpperCase()
  return `US ${sizeCategory} ${offerModalDetails?.size?.display} (New)`
}

export const getConversionPrice = ({
  offerValue,
  isoCode,
  symbol,
  rate,
}: {
  offerValue: string
  isoCode: string
  symbol: string
  rate: number
}) => {
  const value = getNumberValue(offerValue) * 100 * rate
  const conversionPrice = formatPrice(value, isoCode, symbol)

  if (!conversionPrice || conversionPrice === 'Out of stock') {
    return ''
  }

  return conversionPrice
}

export const getButtonText = ({
  offerValue,
  offerExceedsLowestPrice,
  operation,
}: {
  offerValue: string
  offerExceedsLowestPrice: boolean
  operation: 'edit' | 'create'
}) => {
  const currentOffer = getNumberValue(offerValue)
  const offerAboveZero = currentOffer > 0
  const ctaText =
    operation === 'edit'
      ? t(`${TP}.buttonTextEditOffer`, 'Edit offer')
      : t(`${TP}.buttonTextPlaceOffer`, 'Place offer')

  if (offerAboveZero && !offerExceedsLowestPrice) {
    return `${ctaText} - ${offerValue}`
  }

  return ctaText
}
