import { MutableRefObject, useEffect } from 'react'
import styled from 'styled-components'

import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { Button } from 'shared/components/Button'
import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { SlideOutPanel } from 'shared/components/SlideOutPanel'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useClickOutside } from 'shared/hooks'
import { colors } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { setRenderPortal, setRenderSlideOut } from 'store/favoritesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setMyAccountViewOffersShortcut } from 'store/offersSlice'

type LoginInstructionsProps = {
  handleOpenLoginPanelModal: () => void
  currentTab: string
  setCurrentTab: (tab: 'favorites' | 'offers') => void
}

export const LoginInstructions = ({
  handleOpenLoginPanelModal,
  currentTab,
  setCurrentTab,
}: LoginInstructionsProps) => {
  const TP = 'favorites.components.LoginInstructions'

  const isFavoritesEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_FAVORITES)
  const isOffersEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_OFFERS)
  const { country: selectedCountryCode } = useShoppingRegionContext()
  const isShoppingRegionUS = selectedCountryCode === 'US'

  const dispatch = useAppDispatch()

  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => {
    dispatch(setRenderSlideOut(false))
  })

  const { renderSlideOut } = useAppSelector((state) => state.favorites)

  const onAnimationStart = () => {
    if (!renderSlideOut) {
      setTimeout(() => dispatch(setRenderPortal(false)), 300)
    }
  }

  const handleCloseSlideOut = () => {
    dispatch(setRenderSlideOut(false))
    setTimeout(() => dispatch(setRenderPortal(false)), 300)
  }

  const handleLoginClick = () => {
    handleOpenLoginPanelModal()
    dispatch(setRenderSlideOut(false))
  }

  useEffect(() => {
    sendTrackingEvent('FAVORITE_PANEL_VIEW', { is_logged_in: false })
    return () => {
      dispatch(setMyAccountViewOffersShortcut(false))
    }
  }, [])

  if (!isFavoritesEnabled) {
    return null
  }

  return (
    <ClientOnlyPortal
      selector=".main-page-layout"
      withBackdrop
      itemsAlignment="end"
      id="login-instructions"
    >
      <StyledSlideOutPanel
        ref={ref}
        className={renderSlideOut ? 'enter' : 'exit'}
        onAnimationStart={onAnimationStart}
      >
        <FavoritesSlideOutHeader data-qa="FavoritesSlideOutHeader">
          <FavoritesSlideOutTabs>
            <FavoritesSlideOutTab
              onClick={() => setCurrentTab('favorites')}
              $currentTab={currentTab === 'favorites'}
            >
              {t(`${TP}.headerTextFavorites`, 'Favorites')}
            </FavoritesSlideOutTab>
            {isOffersEnabled && isShoppingRegionUS && (
              <FavoritesSlideOutTab
                onClick={() => setCurrentTab('offers')}
                $currentTab={currentTab === 'offers'}
              >
                {t(`${TP}.headerTextOffers`, 'Offers')}
              </FavoritesSlideOutTab>
            )}
          </FavoritesSlideOutTabs>

          <FavoritesSlideOutCloseButton
            data-qa="LoginInstructionsCloseButton"
            onClick={handleCloseSlideOut}
          >
            <CloseX />
          </FavoritesSlideOutCloseButton>
        </FavoritesSlideOutHeader>
        <LoginPrompt data-qa="login-instructions">
          <span>
            {currentTab === 'favorites'
              ? t(
                  `${TP}.loginPromptCtaFavorites`,
                  `Log in to your Flight Club account to view your Favorites.`,
                )
              : t(
                  `${TP}.loginPromptCtaOffers`,
                  `Log in to your Flight Club account to view your Offers.`,
                )}
          </span>

          <Button data-qa="LoginButton" onClick={handleLoginClick}>
            {t(`${TP}.loginButtonCta`, 'Log In')}
          </Button>
        </LoginPrompt>
      </StyledSlideOutPanel>
    </ClientOnlyPortal>
  )
}

const StyledSlideOutPanel = styled(SlideOutPanel)`
  background-color: ${colors.FC2_OFF_WHITE};
`

const FavoritesSlideOutHeader = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: ${colors.FC2_BLACK};
  height: 72px;
  padding: 27px 20px;
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const FavoritesSlideOutTabs = styled.div`
  display: flex;
  gap: 20px;
`

const FavoritesSlideOutTab = styled.button<{ $currentTab: boolean }>`
  color: ${colors.FC2_GREY};
  ${({ $currentTab }) => $currentTab && `text-decoration: underline; color: ${colors.FC2_BLACK};`}
`

const FavoritesSlideOutCloseButton = styled.button`
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
`

const LoginPrompt = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 59px;

  > span {
    font-family: 'Helvetica Neue';
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    width: 323px;
    margin: 30px 0px;
  }
  > button {
    height: 45px;
    max-width: 390px;
    text-transform: uppercase;
    line-height: normal;
  }

  @media (max-width: ${sizes.large / 16}em) {
    margin-bottom: 0px;
    > button {
      width: 335px;
      margin-bottom: 40px;
      line-height: normal;
    }
  }
`
