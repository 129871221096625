import { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useFetchAllUserFavoritesQuery } from 'api/productTemplateListsApi'
import { FavoritesList } from 'favorites/FavoritesList'
import { t } from 'localization'
import { SpinnerIcon } from 'shared/components/Spinner'
import { useUser } from 'shared/hooks/useUser'
import { useWindowSize } from 'shared/hooks/useWindowSize'
import { setRefetchOnCurrencyChange } from 'store/favoritesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { DeleteSizeModal } from './DeleteSizeModal'

type FavoritesListContentsProps = {
  isDeleteSizeModalOpen: boolean
  handleClose: () => void
}

export const FavoritesListContents = ({
  isDeleteSizeModalOpen,
  handleClose,
}: FavoritesListContentsProps) => {
  const TP = 'favorites.components.FavoritesListContents'
  const [mobileSlideOutHeight, setMobileSlideoutHeight] = useState(0)
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)

  const { isMobile } = useWindowSize()
  const { isAuthenticated } = useUser()
  const {
    favoritedSizesForAllPTs,
    currentPaginationToken,
    nextPageQueue,
    refetchOnCurrencyChange,
  } = useAppSelector((state) => state.favorites)
  const { isLoading, isFetching, isUninitialized, refetch } = useFetchAllUserFavoritesQuery(
    {
      paginationToken: currentPaginationToken === 'start' ? '' : currentPaginationToken,
    },
    { skip: !isAuthenticated || currentPaginationToken === undefined },
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isMobile && ref.current) {
      setMobileSlideoutHeight(ref.current.offsetHeight)
    }
  }, [isMobile, ref.current?.offsetHeight])

  useEffect(() => {
    if (!isLoading && !isFetching && !isUninitialized && refetchOnCurrencyChange) {
      refetch()
    }
    dispatch(setRefetchOnCurrencyChange(false))
  }, [refetchOnCurrencyChange, isLoading, isFetching])

  if ((isLoading || isFetching) && nextPageQueue?.[0] === 'start') {
    return (
      <SpinnerWrapper data-qa="SpinnerWrapper" $mobileSlideOutHeight={mobileSlideOutHeight}>
        <SpinnerIcon />
      </SpinnerWrapper>
    )
  }

  if (!favoritedSizesForAllPTs.length) {
    return (
      <EmptyState>
        <span data-qa="FavoritesListContentsEmptyState">
          {t(
            `${TP}.emptyStateCta`,
            'Add items to your Favorites for updates on price changes, restocks and more.',
          )}
        </span>
      </EmptyState>
    )
  }

  return (
    <Wrapper ref={ref}>
      <FavoritesList
        favoritedSizesForAllPTs={favoritedSizesForAllPTs}
        handleSlideOutClose={handleClose}
      />
      {isDeleteSizeModalOpen && <DeleteSizeModal />}
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div<{ $mobileSlideOutHeight: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $mobileSlideOutHeight }) =>
    $mobileSlideOutHeight ? `${$mobileSlideOutHeight}px` : '100%'};
  min-height: 350px;
`

const EmptyState = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 20px;
  font-size: 16px;
  > span {
    width: 323px;
  }
`

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior-y: none;
`
