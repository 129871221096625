import { MutableRefObject, useEffect } from 'react'
import styled from 'styled-components'

import { FavoritesListContents } from 'favorites/FavoritesListContents'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { OffersListContents } from 'offers/OffersListContents'
import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { SlideOutPanel } from 'shared/components/SlideOutPanel'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useClickOutside } from 'shared/hooks'
import { colors } from 'shared/lib'
import {
  setLoggedInToViewFavorites,
  setRenderPortal,
  setRenderSlideOut,
} from 'store/favoritesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  hasActiveOffers,
  hasExpiringOffers,
  setLoggedInToViewOffers,
  setMyAccountViewOffersShortcut,
} from 'store/offersSlice'

type FavoritesSlideOutPanelProps = {
  currentTab: 'favorites' | 'offers'
  setCurrentTab: (tab: 'favorites' | 'offers') => void
}

export const FavoritesSlideOutPanel = ({
  currentTab,
  setCurrentTab,
}: FavoritesSlideOutPanelProps) => {
  const TP = 'favorites.components.FavoritesSlideOutPanel'

  const isFavoritesEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_FAVORITES)
  const isOffersEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_OFFERS)
  const { country: selectedCountryCode } = useShoppingRegionContext()
  const isShoppingRegionUS = selectedCountryCode === 'US'
  const { isDeleteSizeModalOpen, renderSlideOut } = useAppSelector((state) => state.favorites)
  const { isDeleteOfferModalOpen, isOffersModalOpen } = useAppSelector((state) => state.offers)
  const dispatch = useAppDispatch()

  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => {
    if (isDeleteSizeModalOpen || isOffersModalOpen) return
    dispatch(setRenderSlideOut(false))
  })

  const onAnimationStart = () => {
    if (!renderSlideOut) {
      setTimeout(() => dispatch(setRenderPortal(false)), 300)
    }
  }

  const handleCloseSlideOut = () => {
    dispatch(setRenderSlideOut(false))
    setTimeout(() => dispatch(setRenderPortal(false)), 300)
  }

  useEffect(() => {
    sendTrackingEvent('FAVORITE_PANEL_VIEW', { is_logged_in: true })

    return () => {
      dispatch(setMyAccountViewOffersShortcut(false))
      dispatch(setLoggedInToViewFavorites(false))
      dispatch(setLoggedInToViewOffers(false))
    }
  }, [])

  const hasUserExpiringOffers = useAppSelector((state) => hasExpiringOffers(state))
  const hasUserActiveOffers = useAppSelector((state) => hasActiveOffers(state))

  if (!isFavoritesEnabled) {
    return null
  }

  return (
    <ClientOnlyPortal
      selector=".main-page-layout"
      withBackdrop
      itemsAlignment="end"
      id="favorites-slide-out-panel"
    >
      <StyledSlideOutPanel
        ref={ref}
        className={renderSlideOut ? 'enter' : 'exit'}
        onAnimationStart={onAnimationStart}
      >
        <FavoritesSlideOutHeader data-qa="FavoritesSlideOutHeader">
          <FavoritesSlideOutTabs>
            <FavoritesSlideOutTab
              onClick={() => setCurrentTab('favorites')}
              $currentTab={currentTab === 'favorites'}
            >
              {t(`${TP}.headerTextFavorites`, 'Favorites')}
            </FavoritesSlideOutTab>
            {isOffersEnabled && (isShoppingRegionUS || hasUserActiveOffers) && (
              <OffersTabWrapper>
                <FavoritesSlideOutTab
                  onClick={() => setCurrentTab('offers')}
                  $currentTab={currentTab === 'offers'}
                >
                  {t(`${TP}.headerTextOffers`, 'Offers')}
                </FavoritesSlideOutTab>
                {hasUserExpiringOffers && <OffersExpiringIndicator />}
              </OffersTabWrapper>
            )}
          </FavoritesSlideOutTabs>
          <FavoritesSlideOutCloseButton
            data-qa="FavoritesSlideOutPanelCloseButton"
            onClick={handleCloseSlideOut}
          >
            <CloseX />
          </FavoritesSlideOutCloseButton>
        </FavoritesSlideOutHeader>
        {currentTab === 'favorites' && (
          <FavoritesListContents
            isDeleteSizeModalOpen={isDeleteSizeModalOpen}
            handleClose={handleCloseSlideOut}
          />
        )}
        {isOffersEnabled && currentTab === 'offers' && (
          <OffersListContents
            handleClose={handleCloseSlideOut}
            isDeleteOfferModalOpen={isDeleteOfferModalOpen}
          />
        )}
      </StyledSlideOutPanel>
    </ClientOnlyPortal>
  )
}

const StyledSlideOutPanel = styled(SlideOutPanel)`
  background-color: ${colors.FC2_OFF_WHITE};
`

const FavoritesSlideOutHeader = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  height: 72px;
  padding: 27px 20px;
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const FavoritesSlideOutTabs = styled.div`
  display: flex;
  gap: 20px;
`

const FavoritesSlideOutTab = styled.button<{ $currentTab: boolean }>`
  color: ${colors.FC2_GREY};
  ${({ $currentTab }) => $currentTab && `text-decoration: underline; color: ${colors.FC2_BLACK};`}
`

const OffersTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const OffersExpiringIndicator = styled.div`
  background-color: ${colors.FC2_RED};
  height: 4px;
  width: 4px;
  margin: 2px 0px 0px 2px;
`

const FavoritesSlideOutCloseButton = styled.button`
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
`
