import Link from 'next/link'
import { useRouter } from 'next/router'
import { MutableRefObject, useEffect, useRef } from 'react'
import styled from 'styled-components'

import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { useLazyGetProductTemplateQuery } from 'api/productTemplateApi'
import { PTListsByPTResponse, PTListsItem, PTListsProductTemplate } from 'favorites/types'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { OfferModalDetails } from 'offers/types'
import Button from 'shared/components/Button'
import Image from 'shared/components/Image'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts } from 'shared/lib'
import { formatMoneyObject } from 'shared/lib/formatMoneyObject'
import { sizes } from 'shared/lib/media'
import { setCurrentPaginationToken, setFavoriteToBeRemoved } from 'store/favoritesSlice'
import { useAppDispatch } from 'store/hooks'
import { setIsOffersModalOpen } from 'store/offersSlice'

type FavoritesListProps = {
  handleSlideOutClose: () => void
  favoritedSizesForAllPTs: {
    listItems: PTListsByPTResponse[]
    itemsPerPage: number
    paginationToken?: string
  }[]
}

export const FavoritesList = ({
  favoritedSizesForAllPTs,
  handleSlideOutClose,
}: FavoritesListProps) => {
  const TP = 'favorites.components.FavoritesList'
  const isOffersEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_OFFERS)
  const observerRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const dispatch = useAppDispatch()
  const [getProductTemplate] = useLazyGetProductTemplateQuery()
  const router = useRouter()

  useEffect(() => {
    /*
      Note: useEffect used for inifinte scroll. Intersection observer used to observe 
      when element is in view then dispatches an action to the redux store to get the 
      next pagination token in the stack if theres is one. 
     */
    if (observerRef?.current) {
      const cachedRef = observerRef.current
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            dispatch(setCurrentPaginationToken())
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0,
        },
      )

      observer.observe(cachedRef)

      return () => {
        observer.unobserve(cachedRef)
      }
    }
  }, [observerRef.current])

  const handleRemoveClick = ({
    productTemplateId,
    productTemplateSlug,
    sizes,
  }: {
    productTemplateId: string
    productTemplateSlug: string
    sizes: number[]
  }) => {
    sendTrackingEvent('FAVORITE_REMOVE_TAP', {
      product_template_slug: productTemplateSlug,
      size: `${sizes[0]}`,
    })

    dispatch(setFavoriteToBeRemoved({ productTemplateId, sizes, productTemplateSlug }))
  }

  const handleBuyNewClick = (slug: string, size: number) => {
    sendTrackingEvent('FAVORITE_BUY_TAP', { product_template_slug: slug, size: `${size}` })

    const hasMatchingSlug = router?.query && router?.query?.slug?.[0] === slug
    const noSizeOrTypeQueryParams = !router?.query.size && !router?.query.type

    if (hasMatchingSlug) {
      const pathname = noSizeOrTypeQueryParams ? router.asPath : `/${slug}`
      router.replace(
        { pathname, query: { size, type: 'favorite' } },
        { pathname, query: { size, type: 'favorite' } },
        {
          shallow: true,
          locale: router.locale,
        },
      )
    } else {
      router.push(`/${slug}?size=${size}&type=favorite`, `/${slug}?size=${size}&type=favorite`, {
        locale: router.locale,
      })
    }
    handleSlideOutClose()
  }

  const handleMakeOfferClick = ({
    PT,
    item,
  }: {
    PT: PTListsProductTemplate
    item: PTListsItem
  }) => {
    const offerModalDetails: OfferModalDetails = {
      lowestPriceCents: {
        display: formatMoneyObject({
          currency: item?.lowestPriceCents?.currency,
          amount: Number(item?.lowestPriceCents?.amount),
          amountUsdCents: Number(item?.lowestPriceCents?.amountUsdCents),
        }),
        value: Number(item?.lowestPriceCents?.amountUsdCents),
      },
      minimumOfferCents: PT?.minimumOfferCents, // this is fetched later if not available
      name: PT?.name,
      operation: 'create',
      pictureUrl: PT?.pictureUrl,
      productTemplateId: PT?.id,
      size: {
        value: item?.size?.size,
        display: item?.size?.presentationValue,
      },
      sizeCategory: PT?.gender,
      slug: PT?.slug,
    }

    // display modal immediately with available info
    dispatch(setIsOffersModalOpen({ offerModalDetails, status: true }))

    // fetch product template for missing minimum offer cents
    // and re-render modal with updated info
    if (!PT.minimumOfferCents) {
      getProductTemplate(PT.slug, true)
        .unwrap()
        .then((response) => {
          dispatch(
            setIsOffersModalOpen({
              offerModalDetails: {
                ...offerModalDetails,
                minimumOfferCents: response.minimumOfferCents,
              },
              status: true,
            }),
          )
        })
        .catch((e) => {
          Bugsnag.notify(e as NotifiableError)
        })
    }
  }

  return (
    <FavoritesListWrapper id="favorites-list-wrapper" data-qa="FavoritesListWrapper">
      {favoritedSizesForAllPTs.map((favoritesGroupedByPT) => {
        return favoritesGroupedByPT.listItems.map((favorite, primaryIndex) => {
          const PT = favorite.productTemplate
          return (
            <FavoritesCard key={PT.id}>
              <Link href={`/${PT.slug}`}>
                <FavoritesDescription onClick={handleSlideOutClose}>
                  <FavoritesImage>
                    <Image
                      data-qa={`FavoritesListPtImage${primaryIndex}`}
                      alt={PT?.name}
                      src={PT?.picture?.gridPhotoUrl}
                      width={128}
                      height={96}
                    />
                  </FavoritesImage>
                  <FavoritesName data-qa={`FavoritesListPtName${primaryIndex}`}>
                    <span>{PT.name}</span>
                  </FavoritesName>
                </FavoritesDescription>
              </Link>
              <FavoritesSizes>
                {favorite.items.map((item) => {
                  const sizeLabel = `${PT.sizeUnit} ${PT.abbreviatedGender} ${item?.size?.presentationValue}`
                  const { currency, amount, amountUsdCents } = item?.lowestPriceCents || {}
                  const lowestPriceCents = formatMoneyObject(
                    {
                      currency,
                      amount: Number(amount),
                      amountUsdCents: Number(amountUsdCents),
                    },
                    { showCents: false },
                  )

                  return (
                    <FavoritesSingleSize key={`${PT.id}-${item.size.size}`}>
                      <FavoritesProductSize>
                        <div data-qa={`FavoritesListPtSize${primaryIndex}`}>{sizeLabel}</div>
                        <FavoritesRemoveCTA
                          data-qa={`FavoritesListRemoveButton${primaryIndex}`}
                          onClick={() =>
                            handleRemoveClick({
                              productTemplateId: PT?.id,
                              sizes: [item.size.size],
                              productTemplateSlug: PT?.slug,
                            })
                          }
                        >
                          {t(`${TP}.removeCta`, 'Remove')}
                        </FavoritesRemoveCTA>
                      </FavoritesProductSize>
                      <FavoritesButtons className="buttons-container">
                        <Button
                          buttonType="primary2"
                          disabled={lowestPriceCents?.length === 0}
                          $fill
                          onClick={() => handleBuyNewClick(PT.slug, item.size.size)}
                          data-qa={`FavoritesListBuyNewButton${primaryIndex}`}
                        >
                          {lowestPriceCents
                            ? t(`${TP}.buyNewCta`, 'Buy new - {lowestPriceCents}', {
                                lowestPriceCents: ` ${lowestPriceCents}`,
                              })
                            : t(`${TP}.outOfStock`, 'Out of stock')}
                        </Button>
                        {isOffersEnabled && (
                          <Button
                            buttonType="primary1"
                            disabled={lowestPriceCents?.length === 0}
                            $fill
                            onClick={() => handleMakeOfferClick({ PT, item })}
                            data-qa="FavoritesListPlaceOfferButton"
                          >
                            {t(`${TP}.placeOffer`, 'Place Offer')}
                          </Button>
                        )}
                      </FavoritesButtons>
                    </FavoritesSingleSize>
                  )
                })}
              </FavoritesSizes>
            </FavoritesCard>
          )
        })
      })}
      <FavoritesListObserver ref={observerRef} />
    </FavoritesListWrapper>
  )
}

const FavoritesListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0px 20px;
  @media (max-width: ${sizes.large / 16}em) {
    padding: 0px 10px;
  }
`

const FavoritesCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: ${colors.FC2_WHITE};

  > a {
    text-decoration: none;
  }

  @media (max-width: ${sizes.large / 16}em) {
    margin-top: 10px;
  }
`

const FavoritesDescription = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 130px;
  width: 100%;
  @media (max-width: ${sizes.large / 16}em) {
    height: 150px;
  }
`
const FavoritesImage = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0px 20px;
  > img {
    width: 150px;
    max-width: 150px;
    max-height: 130px;
    min-height: 80px;
    object-fit: scale-down;
  }
`

const FavoritesSizes = styled.div`
  & div:not(:last-child) {
    .buttons-container {
      border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
    }
  }
`
const FavoritesSingleSize = styled.div`
  margin: 0px 20px;
`

const FavoritesName = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: auto;
  padding-right: 40px;
  width: 50%;
  max-height: 30px;

  > span {
    display: block;
    color: ${colors.FC2_BLACK};
  }
`

const FavoritesProductSize = styled.div`
  display: flex;
  justify-content: space-between;
  ${fonts.SUBTITLE_3};
  margin: 15px 0px;
`

const FavoritesRemoveCTA = styled.button`
  font-weight: 400;
  font-size: 11px;
  color: ${colors.FC2_GREY};
  cursor: pointer;
`

const FavoritesButtons = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  gap: 20px;
  > button {
    height: 40px;
    line-height: 30px;
    text-transform: uppercase;
    @media (max-width: ${sizes.large / 16}em) {
      width: 100%;
    }
  }
`
const FavoritesListObserver = styled.div`
  height: 50px;
`
