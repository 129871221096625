import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { useLazyGetProductTemplateQuery } from 'api/productTemplateApi'
import { t } from 'localization'
import { useRouter } from 'next/router'
import { OfferModalDetails, OffersListResponse } from 'offers/types'
import { getRemainingOfferTime } from 'offers/utils'
import Button from 'shared/components/Button'
import { colors, fonts } from 'shared/lib'
import { formatMoneyObject } from 'shared/lib/formatMoneyObject'
import { sizes } from 'shared/lib/media'
import { useAppDispatch } from 'store/hooks'
import { setIsOffersModalOpen, setOfferToBeRemoved } from 'store/offersSlice'

type OfferSizesProps = {
  handleSlideOutClose: () => void
  primaryIndex: number
  ptOffers: OffersListResponse
}

export const OfferSizes = ({ ptOffers, primaryIndex, handleSlideOutClose }: OfferSizesProps) => {
  const TP = 'offers.components.OfferSizes'
  const { productTemplate: PT, items } = ptOffers
  const dispatch = useAppDispatch()
  const [getProductTemplate] = useLazyGetProductTemplateQuery()
  const router = useRouter()

  const handleRemoveClick = (wantId: number) => {
    // TODO: Add tracking event
    // sendTrackingEvent('OFFER_REMOVE_TAP', {
    //   wantId
    // })

    dispatch(setOfferToBeRemoved({ wantId }))
  }

  const handleBuyNewClick = (slug: string, size: number) => {
    // TODO: Add tracking event
    // sendTrackingEvent('OFFER_BUY_TAP', { product_template_slug: slug, size: `${size}` })

    const hasMatchingSlug = router?.query && router?.query?.slug?.[0] === slug
    const noSizeOrTypeQueryParams = !router?.query.size && !router?.query.type

    if (hasMatchingSlug) {
      const pathname = noSizeOrTypeQueryParams ? router.asPath : `/${slug}`
      router.replace(
        { pathname, query: { size, type: 'offer' } },
        { pathname, query: { size, type: 'offer' } },
        {
          shallow: true,
          locale: router.locale,
        },
      )
    } else {
      router.push(`/${slug}?size=${size}&type=offer`, `/${slug}?size=${size}&type=offer`, {
        locale: router.locale,
      })
    }
    handleSlideOutClose()
  }

  const handleEditOfferClick = (
    PT: OffersListResponse['productTemplate'],
    item: OffersListResponse['items'][0],
  ) => {
    const offerModalDetails: OfferModalDetails = {
      lowestPriceCents: {
        display: formatMoneyObject(item.lowestPriceCents),
        value: item.lowestPriceCents.amount!,
      },
      minimumOfferCents: PT.minimumOfferCents, // this is fetched later if not available
      name: PT.name,
      offer: item.profileItemData.offer,
      operation: 'edit',
      pictureUrl: PT.pictureUrl,
      productTemplateId: `${PT.id}`,
      size: {
        display: item.size.presentationValue,
        value: item.size.size,
      },
      sizeCategory: PT.gender,
      slug: PT.slug,
    }

    // display modal immediately with available info
    dispatch(setIsOffersModalOpen({ offerModalDetails, status: true }))

    // fetch product template for missing minimum offer cents
    // and re-render modal with updated info
    if (!PT.minimumOfferCents) {
      getProductTemplate(PT.slug, true)
        .unwrap()
        .then((response) => {
          dispatch(
            setIsOffersModalOpen({
              offerModalDetails: {
                ...offerModalDetails,
                minimumOfferCents: response.minimumOfferCents,
              },
              status: true,
            }),
          )
        })
        .catch((e) => {
          Bugsnag.notify(e as NotifiableError)
        })
    }
  }

  return (
    <OfferSizesWrapper>
      {items.map((item) => {
        const sizeLabel = `${PT.sizeUnit} ${PT.abbreviatedGender} ${item?.size?.presentationValue}`
        const { lowestPriceCents } = item
        const { offerAmount } = item.profileItemData.offer
        const ptLowestPriceCents = formatMoneyObject(lowestPriceCents, { showCents: false })
        const {
          display: remainingTime,
          isLessThanADay,
          expired,
        } = getRemainingOfferTime(item.profileItemData.offer)

        return (
          <OfferSingleSize key={`${PT.id}-${item.size.size}`}>
            <OfferProductSize>
              <div data-qa={`OffersListPtSize${primaryIndex}`}>{sizeLabel}</div>
              <OfferDeleteCTA
                data-qa={`OffersListRemoveButton${primaryIndex}`}
                onClick={() => handleRemoveClick(Number(item.profileItemData.offer.id))}
              >
                {t(`${TP}.removeCta`, 'Remove')}
              </OfferDeleteCTA>
            </OfferProductSize>
            <OfferButtons>
              <Button
                buttonType="primary2"
                disabled={isEmpty(lowestPriceCents)}
                $fill
                onClick={() => handleBuyNewClick(PT.slug, item.size.size)}
                data-qa={`OffersListBuyNewButton${primaryIndex}`}
              >
                {lowestPriceCents
                  ? t(`${TP}.buyNewCta`, 'Buy new - {ptLowestPriceCents}', {
                      ptLowestPriceCents,
                    })
                  : t(`${TP}.outOfStock`, 'Out of stock')}
              </Button>
              <Button
                buttonType="primary1"
                $fill
                onClick={() => handleEditOfferClick(PT, item)}
                data-qa={`OffersListEditOfferButton${primaryIndex}`}
              >
                {t(`${TP}.editOffer`, 'Edit Offer - {offerPrice}', {
                  offerPrice: ` ${formatMoneyObject(offerAmount)}`,
                })}
              </Button>
            </OfferButtons>
            <OfferExpiration className="offer-expiration" $urgent={isLessThanADay || expired}>
              {t(`${TP}.expires`, 'Offer expires ')}
              <span>{remainingTime}</span>
            </OfferExpiration>
          </OfferSingleSize>
        )
      })}
    </OfferSizesWrapper>
  )
}

const OfferButtons = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  gap: 20px;
  > button {
    height: 40px;
    line-height: 30px;
    text-transform: uppercase;
    @media (max-width: ${sizes.large / 16}em) {
      width: 100%;
    }
  }
`

const OfferDeleteCTA = styled.button`
  font-weight: 400;
  font-size: 11px;
  color: ${colors.FC2_GREY};
  cursor: pointer;
`

const OfferExpiration = styled.div<{ $urgent: boolean }>`
  font-weight: 400;
  font-size: 12px;
  color: ${({ $urgent }) => ($urgent ? colors.FC2_RED : colors.FC2_BLACK)};
  padding-bottom: 20px;

  span {
    font-weight: 500;
  }
`

const OfferProductSize = styled.div`
  display: flex;
  justify-content: space-between;
  ${fonts.SUBTITLE_3};
  margin: 15px 0px;
`

const OfferSingleSize = styled.div`
  margin: 0px 20px;
`

const OfferSizesWrapper = styled.div`
  & div:not(:last-child) {
    .offer-expiration {
      border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
    }
  }
`
