import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { useRouter } from 'next/router'
import { ChangeEvent, ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  useCreateWantMutation,
  useCreateWantOrderMutation,
  useUpdateWantMutation,
} from 'api/wantsApi'
import { t } from 'localization'
import {
  expirationDays,
  getButtonText,
  getConversionPrice,
  getExpirationDate,
  getNumberValue,
  getShortcutValues,
  getSizingLabel,
} from 'offers/utils'
import Button from 'shared/components/Button'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import Image from 'shared/components/Image'
import Spinner from 'shared/components/Spinner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import useCurrency from 'shared/hooks/useCurrency'
import { useUser } from 'shared/hooks/useUser'
import { colors } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { setRenderPortal, setRenderSlideOut } from 'store/favoritesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  resetOfferState,
  setIsOffersModalOpen,
  setIsPlacingOffer,
  setLoginPromptStatus,
  setPlaceOfferAfterLogin,
} from 'store/offersSlice'

type OffersModalContentsProps = {
  handleCloseOffersModal: () => void
}

export const OffersModalContents = forwardRef(
  ({ handleCloseOffersModal }: OffersModalContentsProps, ref: ForwardedRef<HTMLDivElement>) => {
    const TP = 'offers.components.OffersModalContents'
    const { offerModalDetails, promptLogin, placingOffer, placeOfferAfterLogin } = useAppSelector(
      (state) => state.offers,
    )
    const operation = offerModalDetails?.operation || 'create'
    const { availableCurrencies, currencyCode } = useShoppingRegionContext()
    const { selectedCurrency } = useCurrency()
    const { isAuthenticated, currentUser } = useUser()
    const router = useRouter()
    const dispatch = useAppDispatch()
    const [createWant] = useCreateWantMutation()
    const [updateWant] = useUpdateWantMutation()
    const [createWantOrder] = useCreateWantOrderMutation()
    const shortcutValues = getShortcutValues({ offerModalDetails })
    const [offerValue, setOfferValue] = useState(shortcutValues[0].display)
    const [expirationValue, setExpirationValue] = useState(expirationDays[0])
    const isoCode = selectedCurrency?.isoCode || 'USD'
    const { rate = 1, symbol = '$' } =
      availableCurrencies.find((selectedCurrency) => selectedCurrency.isoCode === currencyCode) ??
      {}
    const lowestPrice = Math.floor((offerModalDetails?.lowestPriceCents?.value ?? 0) / 100)
    const minimumOfferPrice = Math.floor((offerModalDetails?.minimumOfferCents ?? 0) / 100)
    const expirationDate = getExpirationDate(expirationValue)
    const sizingLabel = getSizingLabel(offerModalDetails)
    const conversionPrice = getConversionPrice({ offerValue, isoCode, symbol, rate })
    const offerExceedsLowestPrice = getNumberValue(offerValue) > lowestPrice
    const offerBelowMinimumOfferPrice = getNumberValue(offerValue) < minimumOfferPrice
    const buttonText = getButtonText({
      offerValue,
      offerExceedsLowestPrice,
      operation: operation,
    })

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      let currentValue = e.target.value.replace(/\D/g, '')
      if (currentValue === '$' || !currentValue) {
        return setOfferValue('$')
      }

      if (!currentValue.match(/^\$/)) {
        currentValue = `$${currentValue}`
      }

      if (offerExceedsLowestPrice) {
        if (getNumberValue(currentValue) < lowestPrice) {
          return setOfferValue(currentValue)
        }
        return
      }

      setOfferValue(currentValue)
    }

    const handleCloseSlideOut = () => {
      dispatch(setRenderSlideOut(false))
      setTimeout(() => dispatch(setRenderPortal(false)), 300)
    }

    const handleBuyNewClick = () => {
      const slug = offerModalDetails?.slug
      const size = offerModalDetails?.size?.display
      const hasMatchingSlug = router?.query && router?.query?.slug?.[0] === slug
      const noSizeOrTypeQueryParams = !router?.query.size && !router?.query.type

      if (hasMatchingSlug) {
        const pathname = noSizeOrTypeQueryParams ? router.asPath : `/${slug}`
        router.replace(
          { pathname, query: { size, type: 'offer' } },
          { pathname, query: { size, type: 'offer' } },
          {
            shallow: true,
            locale: router.locale,
          },
        )
      } else {
        router.push(`/${slug}?size=${size}&type=offer`, `/${slug}?size=${size}&type=offer`, {
          locale: router.locale,
        })
      }
      handleCloseSlideOut()
      handleCloseOffersModal()
    }

    const placeOffer = useCallback(() => {
      dispatch(setIsPlacingOffer(true))
      createWant({
        offerAmountCents: getNumberValue(offerValue) * 100,
        offerDuration: expirationValue * 24,
        productTemplateId: Number(offerModalDetails?.productTemplateId ?? '0'),
        size: offerModalDetails?.size?.value || 0,
        userId: currentUser?.id || 0,
      })
        .unwrap()
        .then((payloadFromCreateOffer) => {
          return createWantOrder({ wantId: payloadFromCreateOffer?.id }).unwrap()
        })
        .then((payloadFromWantOrder) => {
          const route = `/checkout/${payloadFromWantOrder?.number}`
          void router.push(route, route, { locale: router.locale })
        })
        .catch((error) => Bugsnag.notify(error as NotifiableError))
    }, [
      dispatch,
      createWant,
      createWantOrder,
      offerValue,
      expirationValue,
      offerModalDetails,
      currentUser,
      router,
    ])

    const editOffer = () => {
      dispatch(setIsPlacingOffer(true))

      updateWant({
        wantId: `${offerModalDetails?.offer?.id || 0}`,
        offerAmountCents: getNumberValue(offerValue) * 100,
        offerDuration: expirationValue * 24,
      })
        .unwrap()
        .then((payloadFromEditOrder) => {
          dispatch(setIsOffersModalOpen({ status: !payloadFromEditOrder.id }))
        })
        .catch((error) => Bugsnag.notify(error as NotifiableError))
    }

    const handleCTAOfferClick = async (operation: 'create' | 'edit') => {
      if (!isAuthenticated) {
        return dispatch(setLoginPromptStatus(!isAuthenticated))
      }

      if (operation === 'create') {
        placeOffer()
      } else {
        editOffer()
      }
    }

    // Update offer value when editing an offer
    useEffect(() => {
      if (operation === 'edit' && offerModalDetails && offerModalDetails.offer) {
        setOfferValue(`$${offerModalDetails.offer.offerAmount.amount / 100}`)
        setExpirationValue(offerModalDetails.offer.offerDuration / 24)
      }
    }, [operation, offerModalDetails])

    // Create offer after login
    useEffect(() => {
      if (placeOfferAfterLogin && !promptLogin && offerModalDetails) {
        placeOffer()
        dispatch(setPlaceOfferAfterLogin(false))
      }
    }, [dispatch, placeOffer, promptLogin, offerModalDetails, placeOfferAfterLogin])

    useEffect(() => {
      return () => {
        dispatch(resetOfferState())
      }
    }, [dispatch])

    return (
      <Wrapper ref={ref} data-qa="OffersModalContentsWrapper">
        <Spinner showSpinner={!!placingOffer}>
          <ModalHeader>
            <BuyNowWrapper>
              <ImageWrapper>
                {offerModalDetails && (
                  <Image
                    data-qa="OffersModalImage"
                    alt={offerModalDetails?.name}
                    src={offerModalDetails?.pictureUrl}
                    width={640}
                    height={640}
                  />
                )}
              </ImageWrapper>
              <BuyNowDetails>
                <button onClick={handleBuyNewClick} data-qa="OffersModalBuyNowButton">
                  {t(`${TP}.buyNowCta`, 'Buy Now - {lowestPriceCents}', {
                    lowestPriceCents: `${offerModalDetails?.lowestPriceCents?.display}`,
                  })}
                </button>
                <span data-qa="OffersModalSizingLabel">{sizingLabel}</span>
              </BuyNowDetails>
            </BuyNowWrapper>
            <CloseButton data-qa="OffersModalCloseButton" onClick={handleCloseOffersModal}>
              <CloseX width="15px" height="15px" />
            </CloseButton>
          </ModalHeader>

          <OfferInputLabelWrappper>
            <Label>{t(`${TP}.yourOffer`, 'Your Offer')}</Label>
            {offerExceedsLowestPrice && (
              <ExceedsLowestPrice data-qa="OffersModalExceedsLowestPriceWarning">
                {t(`${TP}.exceedsLowestPrice`, 'Your offer exceeds the Lowest Price')}
              </ExceedsLowestPrice>
            )}
            {offerBelowMinimumOfferPrice && (
              <ExceedsLowestPrice data-qa="OffersModalExceedsLowestPriceWarning">
                {t(
                  `${TP}.belowMinimumOfferPrice`,
                  'Please place an offer above ${minimumOfferPrice}',
                  {
                    minimumOfferPrice,
                  },
                )}
              </ExceedsLowestPrice>
            )}
          </OfferInputLabelWrappper>

          <OfferInputWrapper>
            <OfferInput
              aria-label="offer-input"
              data-qa="OffersModalInputValue"
              type="text"
              value={`${offerValue}`}
              onChange={handleOnChange}
              onFocus={() => setOfferValue('$')}
            />
            {isoCode !== 'USD' && <OfferValueConversion>{conversionPrice}</OfferValueConversion>}
          </OfferInputWrapper>

          <Shortcuts>
            {shortcutValues.map((value) => {
              return (
                <ShortcutButton
                  data-qa="OffersModalShortcutButton"
                  key={`${value.display}-${value?.percentageDisplay}`}
                  onClick={() => setOfferValue(value.display)}
                  $isSelected={offerValue === value.display}
                >
                  <ShortcutOfferValue>{value.display}</ShortcutOfferValue>
                  <ShortcutPercentageValue>{value.percentageDisplay}</ShortcutPercentageValue>
                </ShortcutButton>
              )
            })}
          </Shortcuts>

          <ExpirationLabelWrapper>
            <Label>{t(`${TP}.offerExpiration`, 'Offer Expiration')}</Label>
            <span>{expirationDate}</span>
          </ExpirationLabelWrapper>
          <ExpirationDays>
            {expirationDays.map((number) => {
              return (
                <ExpirationButton
                  data-qa="OffersModalExpirationButton"
                  key={number}
                  $isSelected={expirationValue === number}
                  onClick={() => setExpirationValue(number)}
                >
                  <div>{`${number} days`}</div>
                </ExpirationButton>
              )
            })}
          </ExpirationDays>

          <AcceptedOfferInstructions>
            {t(
              `${TP}.acceptedOfferInstructions`,
              'If the seller accepts, you will receive an confirmation email. Check out immediately.',
            )}
          </AcceptedOfferInstructions>

          <Button
            buttonType="primary2"
            disabled={
              !getNumberValue(offerValue) ||
              offerExceedsLowestPrice ||
              offerBelowMinimumOfferPrice ||
              placingOffer
            }
            $fill
            onClick={() => handleCTAOfferClick(operation)}
            data-qa="OffersModalPlaceOfferButton"
          >
            {buttonText}
          </Button>
        </Spinner>
      </Wrapper>
    )
  },
)

OffersModalContents.displayName = 'OffersModalContents'

const Wrapper = styled.div`
  background-color: ${colors.FC2_WHITE};
  padding: 18px;
  height: 544px;
  max-width: 430px;
  margin: auto;
  position: relative;

  @media (max-width: ${sizes.large / 16}em) {
    width: 100vw;
    height: 100%;
    padding: 20px;
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const BuyNowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`

const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 14px;
`

const BuyNowDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  > button {
    text-decoration: underline;
    margin-bottom: 4px;
  }
`

const CloseButton = styled.button`
  width: 15px;
  height: 15px;
`

const Label = styled.label`
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
`

const OfferInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const OfferInputLabelWrappper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ExceedsLowestPrice = styled.div`
  color: #ff0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const OfferInput = styled.input`
  height: 60px;
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  margin: 15px 0px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: ${sizes.large / 16}em) {
    font-size: 20px;
  }

  &:focus {
    outline: 1px solid black;
    border: none;
    margin: 15px 1px;
  }
`

const OfferValueConversion = styled.div`
  position: absolute;
  right: 20px;
  top: 39px;

  @media (max-width: ${sizes.large / 16}em) {
    right: 16px;
  }
`

const Shortcuts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 14px;
  margin-bottom: 40px;

  @media (max-width: ${sizes.large / 16}em) {
    gap: 11px;
  }
`

const ShortcutOfferValue = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
`
const ShortcutPercentageValue = styled.div`
  color: ${colors.FC2_GREY};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const ShortcutButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 122px;
  height: 60px;
  border: 1.7px solid
    ${({ $isSelected }) => ($isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY)};

  @media (max-width: ${sizes.large / 16}em) {
    min-width: 105px;
    height: 60px;
  }
`

const ExpirationLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ExpirationDays = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin: 16px 0px 40px 0px;

  @media (max-width: ${sizes.large / 16}em) {
    gap: 10px;
  }
`

const ExpirationButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 36px;
  border: 1.7px solid
    ${({ $isSelected }) => ($isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY)};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;

  @media (max-width: ${sizes.large / 16}em) {
    min-width: 77px;
  }
`
const AcceptedOfferInstructions = styled.div`
  text-align: center;
  width: 352px;
  margin: auto auto 40px auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: ${sizes.large / 16}em) {
    font-size: 11px;
    width: 100%;
  }
`
