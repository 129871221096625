import styled from 'styled-components'

export const DefaultCard = () => (
  <CustomSvg
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="32" rx="2" fill="#D6D6D6" />
    <rect x="8" y="8" width="8" height="6" rx="1.18519" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 22H8V24H13V22ZM22 22H17V24H22V22ZM26 22H31V24H26V22ZM40 22H35V24H40V22Z"
      fill="#999999"
    />
  </CustomSvg>
)

const CustomSvg = styled.svg`
  height: 32px;
  min-height: 32px;
  min-width: 48px;
  width: 48px;
`
