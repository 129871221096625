import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { productTemplateListsApi } from 'api/productTemplateListsApi'
import { usersApi } from 'api/usersApi'
import { wantsApi } from 'api/wantsApi'
import { OfferModalDetails, OffersListResponse } from 'offers/types'
import { getRemainingOfferTime } from 'offers/utils'
import { RootState } from 'store/store'

type OffersState = {
  allUserOffers: OffersListResponse[]
  currentPaginationToken: string | undefined
  isDeleteOfferModalOpen: boolean
  isOffersModalOpen: boolean
  loggedInToViewOffers: boolean | undefined
  myAccountViewOffersShortcut: boolean | undefined
  nextPageQueue: string[]
  offerModalDetails: OfferModalDetails | null
  offerToBeRemoved: number | null
  placingOffer: boolean | undefined
  placeOfferAfterLogin: boolean | undefined
  promptLogin: boolean | undefined
  refetchOnOfferUpdate: boolean
}

const initialState: OffersState = {
  allUserOffers: [],
  currentPaginationToken: 'start',
  isDeleteOfferModalOpen: false,
  isOffersModalOpen: false,
  loggedInToViewOffers: false,
  myAccountViewOffersShortcut: false,
  nextPageQueue: ['start'],
  offerModalDetails: null,
  offerToBeRemoved: null,
  placingOffer: false,
  placeOfferAfterLogin: false,
  promptLogin: false,
  refetchOnOfferUpdate: false,
}

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetOfferState: (state) => {
      state.placingOffer = false
      state.offerModalDetails = null
      state.isOffersModalOpen = false
    },
    setCurrentPaginationToken: (state) => {
      state.currentPaginationToken = state.nextPageQueue[0]
      state.nextPageQueue = state.nextPageQueue.slice(1)
    },
    setIsDeleteOfferModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteOfferModalOpen = action.payload
    },
    setIsOffersModalOpen: (
      state,
      action: PayloadAction<{ offerModalDetails?: OfferModalDetails; status: boolean }>,
    ) => {
      state.offerModalDetails = action.payload?.offerModalDetails || null
      state.isOffersModalOpen = action.payload.status
      if (action.payload.status === false) {
        state.offerModalDetails = null
      }
    },
    setIsPlacingOffer: (state, action: PayloadAction<boolean | undefined>) => {
      state.placingOffer = action.payload
      state.promptLogin = false
    },
    setLoginPromptStatus: (state, action: PayloadAction<boolean | undefined>) => {
      state.promptLogin = action.payload
    },
    setLoggedInToViewOffers: (state, action: PayloadAction<boolean | undefined>) => {
      state.loggedInToViewOffers = action.payload
      if (action.payload === true) {
        state.promptLogin = false
      }
    },
    setMyAccountViewOffersShortcut: (state, action: PayloadAction<boolean | undefined>) => {
      state.myAccountViewOffersShortcut = action.payload
    },
    setOfferToBeRemoved: (state, action: PayloadAction<{ wantId: number }>) => {
      state.offerToBeRemoved = action.payload.wantId
      state.isDeleteOfferModalOpen = true
    },
    setPlaceOfferAfterLogin: (state, action: PayloadAction<boolean | undefined>) => {
      state.placeOfferAfterLogin = action.payload
      state.promptLogin = false
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productTemplateListsApi.endpoints.fetchAllUserOffers.matchFulfilled,
      (state, action) => {
        // Remove the last pagination token from the queue since it has been used already
        state.nextPageQueue = state.nextPageQueue.slice(1)

        // Check if the newest page has a pagination token for the next page
        // Make sure there are no duplicated tokens in queue
        if (
          action?.payload.paginationToken &&
          !state.nextPageQueue.includes(action.payload.paginationToken)
        ) {
          state.nextPageQueue = [...state.nextPageQueue, action.payload.paginationToken]
        }

        // At start of page 1 return an array with first response
        // If user removes all offers, return empty array to display empty state message
        const payload = action.payload.listItems || []
        if (state.currentPaginationToken === 'start') {
          state.allUserOffers = payload
        } else {
          // merge into one array for infinite scroll
          state.allUserOffers = [...state.allUserOffers, ...payload]
        }
      },
    )

    builder.addMatcher(wantsApi.endpoints.createWantOrder.matchFulfilled, (state) => {
      state.promptLogin = false
      state.loggedInToViewOffers = false
    })

    builder.addMatcher(wantsApi.endpoints.createWantOrder.matchRejected, (state) => {
      state.placingOffer = false
    })

    builder.addMatcher(wantsApi.endpoints.updateWant.matchFulfilled, (state, action) => {
      // Update offers list state for faster UI response before refetching all offers
      outerLoop: for (const offersList of state.allUserOffers) {
        for (const item of offersList.items) {
          if (Number(item.profileItemData.offer.id) === action.payload.id) {
            item.profileItemData.offer.offerAmount = action.payload.localizedOfferAmountCents
            item.profileItemData.offer.offerDuration = action.payload.offerDuration
            item.profileItemData.offer.activatedAt = action.payload.offerStartTime
            break outerLoop
          }
        }
      }
    })

    builder.addMatcher(wantsApi.endpoints.deleteWant.matchFulfilled, (state, action) => {
      // Update offers list state for faster UI response before refetching all offers
      outerLoop: for (const offersList of state.allUserOffers) {
        for (let i = 0; i < offersList.items.length; i++) {
          if (Number(offersList.items[i].profileItemData.offer.id) === action.payload.id) {
            offersList.items.splice(i, 1)
            break outerLoop
          }
        }
      }
    })

    builder.addMatcher(usersApi.endpoints.logout.matchFulfilled, (state) => {
      state.isOffersModalOpen = false
      state.loggedInToViewOffers = false
      state.myAccountViewOffersShortcut = false
      state.offerModalDetails = null
      state.placingOffer = false
      state.placeOfferAfterLogin = false
      state.promptLogin = false
    })

    builder.addMatcher(usersApi.endpoints.login.matchFulfilled, (state) => {
      state.loggedInToViewOffers = false
    })
  },
})

const { actions, reducer: offersReducer } = offersSlice

export const {
  resetOfferState,
  setCurrentPaginationToken,
  setIsDeleteOfferModalOpen,
  setIsOffersModalOpen,
  setIsPlacingOffer,
  setLoginPromptStatus,
  setLoggedInToViewOffers,
  setMyAccountViewOffersShortcut,
  setOfferToBeRemoved,
  setPlaceOfferAfterLogin,
} = actions

export { offersReducer }

export const hasExpiringOffers = createSelector(
  (state: RootState) =>
    state.offers.allUserOffers.some((currentOffer) => {
      const expiring = currentOffer.items.some(
        (item) =>
          getRemainingOfferTime({
            activatedAt: item.profileItemData.offer.activatedAt,
            offerDuration: item.profileItemData.offer.offerDuration,
          }).isLessThanADay,
      )

      return expiring
    }),
  (hasExpiringOffers) => hasExpiringOffers,
)

export const hasActiveOffers = createSelector(
  (state: RootState) =>
    state.offers.allUserOffers.some((currentOffer) =>
      currentOffer.items.some((item) => {
        const { expired } = getRemainingOfferTime({
          activatedAt: item.profileItemData.offer.activatedAt,
          offerDuration: item.profileItemData.offer.offerDuration,
        })
        return !expired
      }),
    ),
  (hasActiveOffers) => hasActiveOffers,
)

export const getActiveOffers = createSelector(
  (state: RootState) => {
    // get offers that are not expired
    const activeOffers = state.offers.allUserOffers.reduce<OffersListResponse[]>(
      (accOffers, currentOffer) => {
        const active = currentOffer.items.filter((item) => {
          const { expired } = getRemainingOfferTime({
            activatedAt: item.profileItemData.offer.activatedAt,
            offerDuration: item.profileItemData.offer.offerDuration,
          })
          return !expired
        })

        if (active.length) {
          return [...accOffers, { items: active, productTemplate: currentOffer.productTemplate }]
        }

        return accOffers
      },
      [],
    )

    return activeOffers
  },
  (activeOffers) => activeOffers,
)
